import React, { useState, useEffect, FunctionComponent } from "react";

import { INTERCOM_APP_ID } from "../../env-exports";
import { getCookie } from "../utils/account-helper";

type UseIntercomIsReadyHookProps = {
  loggedIntoIntercom?: boolean;
  onIsReadyCallback: () => void;
};

const useIntercomIsReadyHook = (
  props: UseIntercomIsReadyHookProps
): boolean => {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (props.loggedIntoIntercom) {
      setTimeout(() => {
        const sessionCookie = getCookie(`intercom-session-${INTERCOM_APP_ID}`);
        if (sessionCookie != null) {
          props.onIsReadyCallback();
        } else {
          setIsError(true);
        }
      }, 3000);
      // eslint-disable-next-line
    }
  }, [props.loggedIntoIntercom]);
  return isError;
};

export default useIntercomIsReadyHook;
