// COPIED FROM MOBILE
const safeJsonParse = <T>(serializedString: string | undefined): T | null => {
  if (serializedString == null) return null;
  try {
    return JSON.parse(serializedString) as T;
  } catch (err) {
    // Swallow error...
  }
  return null;
};

export default safeJsonParse;
