import { getUserData } from "halter-common-login";
import safeJsonParse from "../utils/safe-json-parse";
import { parseCookies } from "halter-common-login";

export type IntercomToken = {
  hmac: {
    web: string;
  };
};

export type UserAccount = {
  intercom: IntercomToken | null;
  firstName: string | undefined;
  email: string | undefined;
};

const ACCOUNT_ATTRIBUTES_MAP: Record<keyof UserAccount, string> = {
  intercom: "custom:intercom",
  firstName: "given_name",
  email: "email",
};

export async function getCurrentUser(): Promise<UserAccount | undefined> {
  const user = getUserData();
  if (user == null) return;
  return {
    intercom: safeJsonParse<IntercomToken>(
      user[ACCOUNT_ATTRIBUTES_MAP.intercom]
    ),
    firstName: user[ACCOUNT_ATTRIBUTES_MAP.firstName],
    email: user[ACCOUNT_ATTRIBUTES_MAP.email],
  };
}

export function getCookie(cookieName: string): string | null {
  const cookie = parseCookies(document.cookie).filter((cookie) =>
    cookie.key.match(cookieName)
  );

  return (cookie ? cookie.pop()?.value : null) || null;
}
