import React from "react";

import useIntercomHook from "./useIntercomHook";
import useIntercomIsReadyHook from "./useIntercomIsReadyHook";
import { INTERCOM_HELP_URL } from "../../env-exports";

import "./redirect.css";
import cowIllustration from "./Halter_Jersy_spots_Final_2.png";

function redirectToIntercom() {
  window.location.replace(INTERCOM_HELP_URL!);
}

function Redirect() {
  const { isAuthenticated, isError } = useIntercomHook();
  const redirectError = useIntercomIsReadyHook({
    loggedIntoIntercom: isAuthenticated,
    onIsReadyCallback: redirectToIntercom,
  });

  const hasError = redirectError || isError;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        color: "#ffffff",
      }}
    >
      {hasError ? (
        <div className="helper">
          <img
            style={{ maxHeight: "250px", maxWidth: "250px" }}
            src={cowIllustration}
          />
          <div style={{ margin: "24px 0 0 0" }}>
            <h1>Hmmm...</h1>
            <div
              style={{
                margin: "-16px 10px 0 10px",
                maxWidth: "300px",
                lineHeight: "1.5rem",
              }}
            >
              <p>
                We are having trouble signing you in. <br></br>
                Please use the messenger below to get in touch.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="loader"></div>
      )}
    </div>
  );
}

export default Redirect;
