import React, { useState, useEffect } from "react";

import { useIntercom } from "react-use-intercom";
import { getCurrentUser } from "../utils/account-helper";

const useIntercomHook = () => {
  const { boot, update } = useIntercom();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const intercomInit = async (): Promise<void> => {
    boot();

    try {
      const user = await getCurrentUser();

      if (user == null) {
        setIsError(true);
        return;
      }

      update({
        userHash: user.intercom?.hmac.web,
        name: user.firstName,
        email: user.email,
      });

      setIsAuthenticated(true);
    } catch (err) {
      setIsError(true);
    }
  };

  useEffect(() => void intercomInit(), []);
  return { isAuthenticated, isError };
};

export default useIntercomHook;
