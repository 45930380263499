import { types } from "halter-common-login";

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const INTERCOM_HELP_URL = process.env.REACT_APP_INTERCOM_HELP_URL;
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export const environment =
  (process.env.REACT_APP_ENVIRONMENT as types.Environment) || undefined;

export const awsAuthConfig = {
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
};
